/** @format */

import {
  bindable,
  inject,
  customElement,
  bindingMode
} from 'aurelia-framework';

@customElement('input-numeric')
@inject(Element)
export class InputNumeric {
  //prettier-ignore
  @bindable({
    defaultBindingMode: bindingMode.twoWay
  }) value;
  @bindable placeholder;
  @bindable maxlength;
  @bindable cssClass;
  @bindable change;
  @bindable name;
  @bindable label;
  @bindable postLabel;
  @bindable format;
  @bindable allowNegative;
  @bindable disabled;
  @bindable required;
  @bindable min;
  @bindable max;

  constructor(element) {
    this.element = element

    this.onKeydown = this.onKeydown.bind(this);
    this.onChange = this.onChange.bind(this);

    this.noFormat = false;
    this.label = null;
    this.min = null;
    this.max = null;
    this.postLabel = null;
    this.disabled = false;
  }

  /**
   *
   * Refactor this, to only use numberal-vc when format is not empty
   * for now some input-numeric dont pass format but rely on numeral-vc default .format
   * we should make this format explici, otherwise it should a normal text field that only takes digits
   */
  formatChanged(value) {
    this.noFormat = value === 'false' || value === false;
  }

  attached() {
    this.inputElement.inputRef.addEventListener('keydown', this.onKeydown);
  }

  detached() {
    this.inputElement.inputRef.removeEventListener('keydown', this.onKeydown);
  }

  onChange(e) {
    //trim whitespace
    if (typeof this.value === 'string') {
      this.value = this.value.replace(/\s/g, '');
    }

    if (this.min && parseInt(this.value) < this.min) {
      this.value = this.min;
    }
    if (this.max && parseInt(this.value) > this.max) {
      this.value = this.max;
    }

    if (this.change) {
      this.change(e);
    }
  }

  onKeydown(e) {
    if (this.isNavOrSelect(e)) {
      return;
    }

    //https://github.com/elm-lang/keyboard/issues/3
    //https://stackoverflow.com/questions/18177818/why-jquerys-event-which-gives-different-results-in-firefox-and-chrome
    if (this.allowNegative && (e.key === '-' && !e.shiftKey)) {
      return;
    }

    //prettier-ignore
    // If it's not a number, prevent the keypress...
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }

  // http://stackoverflow.com/a/995193/725866
  //prettier-ignore
  isNavOrSelect(e) {
    // Allow: backspace, delete, tab, escape, enter and .
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A/X/C/V, Command+A/X/C/V
      ([65, 67, 86, 88].indexOf(e.keyCode) !== -1 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40)) {
      // let it happen, don't do anything
      return true;
    }
    return false;
  }

  changeElementStatus(event) {
    var eventDetails = new CustomEvent('element-status-changed', {
      detail: event.detail,
      bubbles: true
    });

    this.element.dispatchEvent(eventDetails);
  }
}
