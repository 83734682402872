/** @format */

import { bindable, bindingMode } from 'aurelia-framework';
import userService from 'services/api/userService';

import './email-input.scss';

export class EmailInput {
  @bindable({ defaultBindingMode: bindingMode.twoWay })
  value;
  @bindable({ defaultBindingMode: bindingMode.twoWay })
  emails;
  @bindable disabled;

  @bindable label;
  @bindable cssClass;
  @bindable config;

  @bindable onChanged;

  constructor() {
    this.userService = userService;

    this.value = null;
    this.cssClass = '';
    this.label = null;
    this.suggestions = [];
    this.disabled = false;
    this.config = {};

    this.onTagsValueChange = this.onTagsValueChange.bind(this);
  }

  emailsChanged(emails) {
    if (!this._internal) {
      this._internal = true;
      this.value = emails && emails.join ? emails.join(',') : '';
    }
    this._internal = false;
  }

  valueChanged(value) {
    if (!this._internal) {
      this._internal = true;
      this.emails = value.length ? value.replace(/\s/g, '').split(',') : [];
    }
    this._internal = false;
  }

  onTagsValueChange(value) {
    if (value && value.length > 0) {
      userService.searchUsers(value).then(result => {
        this.suggestions = (result || []).map(r => {
          return { id: r.id, value: r.username, label: r.name };
        });
      });
    }
  }
}
