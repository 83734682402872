/** @format */

import {
  bindable,
  inject,
  customElement,
  computedFrom
} from 'aurelia-framework';

@customElement('input-numeric-units-select')
@inject(Element)
export class InputNumericUnitsSelect {

  @bindable value;
  @bindable unit;
  @bindable mergedValueUnit;
  @bindable units;
  @bindable valueLabel;
  @bindable unitLabel;
  @bindable name;
  @bindable maxlength
  @bindable allowNegative;
  @bindable defaultValue;
  @bindable disabled;
  @bindable minMaxRules;

  constructor(element) {
    this.element = element

    this.value = null;
    this.unit = null;
    this.minMaxRules = {};
    this.mergedValueUnit = null;
    this.units = {};
    this.valueLabel = null;
    this.unitLabel = 'unit';
    this.name = 'numericValue';
    this.allowNegative = false;
    this.defaultValue = true;
    this.disabled = false;
  }

  attached() {
    if (this.mergedValueUnit && !this.value && !this.unit) {
      this.checkMergedValue();
    }
    if (!this.unit) {
      this.unit = Object.keys(this.units)[0];
    }
    if (!this.value) {
      this.value = this.defaultValue ? 0 : null;
    }
  }

  detached() {
    this.value = (this.defaultValue) ? '0' : '';
    this.unit = Object.keys(this.units)[0];
  }

  checkMergedValue() {
    if (this.mergedValueUnit) {
      var value = parseInt(this.mergedValueUnit.match(/\d/g).join(""));
      if (value) {
        this.value = value;
      } else {
        this.value = (this.defaultValue) ? '0' : '';
      }
      var unit = this.mergedValueUnit.replace(/\d+/g, '');
      this.unit = (unit && Object.keys(this.units).find(unitKey => unitKey == unit)) ? unit : Object.keys(this.units)[0];

      if (!this.value || !this.unit) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  valueChanged(newValue, oldValue) {
    if (!this.value) {
      this.value = (this.defaultValue) ? '0' : '';
    }
    this.dispatchObject()
  }

  unitChanged(newUnit, oldUnit) {
    if (!this.unit) {
      this.unit = oldUnit;
    }
    this.dispatchObject()
  }

  dispatchObject() {
    var eventDetails = new CustomEvent('on-value-update', {
      detail: {
        value: this.value,
        unit: this.unit,
        merge: this.mergedValueUnit
      },
      bubbles: true
    });

    this.element.dispatchEvent(eventDetails);
  }

  @computedFrom('minMaxRules', 'unit')
  get minValueRule() {
    return (this.minMaxRules && this.unit && this.minMaxRules[this.unit]) ? this.minMaxRules[this.unit].min : null;
  }

  @computedFrom('minMaxRules', 'unit')
  get maxValueRule() {
    return (this.minMaxRules && this.unit && this.minMaxRules[this.unit]) ? this.minMaxRules[this.unit].max : null;
  }
}
